type Currency = {
    code: string
    name: string
    symbol: string
}

type Wallet = {
    id: string
    name: string
    balance: number
    currency: Currency
}

export type EventEmitterState = {
    isAuth: boolean
    wallet: Wallet | null
    unreadMessageCount: number
    isWalletLoading: boolean
}

export type EventEmitterListener = (payload: EventEmitterState) => void;

export type EventEmitterChannel = 'updateWallet' | 'isWalletLoading' | 'refreshWallet' | 'unreadMessageCount'

export const eventEmitterInitialState: EventEmitterState = {
    isAuth: false,
    wallet: null,
    unreadMessageCount: 0,
    isWalletLoading: false
}

export class EventEmitter {
    private state: EventEmitterState = eventEmitterInitialState

    private channels: Record<EventEmitterChannel, EventEmitterListener[]> = {
        updateWallet: [],
        isWalletLoading: [],
        refreshWallet: [],
        unreadMessageCount: [],
    }

    on = (channelOrChannels: EventEmitterChannel | EventEmitterChannel[], listener: EventEmitterListener) => {
        if (Array.isArray(channelOrChannels)) {
            channelOrChannels.forEach(channel => {
                this.channels[channel].push(listener)
            })
        } else {
            this.channels[channelOrChannels].push(listener)
        }

        return () => this.off(channelOrChannels, listener)
    };

    off = (channelOrChannels: EventEmitterChannel | EventEmitterChannel[], listener: EventEmitterListener) => {
        if (Array.isArray(channelOrChannels)) {
            channelOrChannels.forEach(channel => {
                this.channels[channel] = this.channels[channel].filter(l => l !== listener)
            })
        } else {
            this.channels[channelOrChannels] = this.channels[channelOrChannels].filter(l => l !== listener)
        }
    };

    notify = (channel: EventEmitterChannel, partialState?: Partial<EventEmitterState>) => {
        console.log('notify', channel, partialState)
        this.state = { ...this.state, ...(partialState || {}) }

        this.channels[channel].forEach((listener) => listener(this.state));
    };
}
