import {
  LifeCycles,
  ParcelConfig,
  registerApplication,
  start,
} from "single-spa";
import { EventEmitter } from "@kingmakers-tech/event-emitter";

const eventEmitter = new EventEmitter();

// trigger a pipeline

registerApplication<{
  betking: Promise<ParcelConfig>;
  eventEmitter: EventEmitter;
}>({
  name: "@kingmakers/layout",
  app: () => System.import<LifeCycles>("@kingmakers/layout"),
  activeWhen: ["/"],
  customProps: {
    betking: System.import<ParcelConfig>("@kingmakers/betking"),
    eventEmitter,
  },
});

start({
  urlRerouteOnly: false,
});
